<template>
  <div id="content" class="app-content">
    <!-- page header -->
    <b-row>
      <b-col>
        <h1 class="page-header">Giftcards <small>all listings.</small></h1>
      </b-col>
      <b-col>
        <b-button class="float-right" variant="primary" @click="addGiftcard = !addGiftcard">Add giftcard</b-button>

        <b-button class="float-right mr-2" variant="primary" @click="combineGiftcards = !combineGiftcards">
          Combine giftcards
        </b-button>
      </b-col>
    </b-row>
    <b-card>
      <b-row v-if="combineGiftcards">
        <b-col class="mb-4">
          <b-input-group v-for="(point, index) in giftcardsToCombine" :key="index" class="mb-3">
            <keep-alive>
              <b-form-input
                :id="`formBulletPointsField${index}`"
                placeholder="23423432234"
                v-model="giftcardsToCombine[index].code"
                style="border-top-right-radius: 0; border-bottom-right-radius: 0; width: 350px"
              />
            </keep-alive>
            <template #append>
              <b-button variant="danger" @click="() => removeBulletPoint(index)">
                <strong>&times;</strong>
              </b-button>
            </template>
          </b-input-group>

          <b-button variant="light" @click="addEmptyBulletPoint">
            Add code
          </b-button>
          <br />
          <b-button class="mt-3" variant="primary" @click="doCombine">
            Combine
          </b-button>
        </b-col>
      </b-row>
      <b-row v-if="addGiftcard">
        <b-col>
          <h3>Add giftcard</h3>
          <form>
            <b-form-group label="Valid to date" label-for="valid-to">
              <b-form-datepicker
                id="valid-to"
                v-model="newGiftcard.valid_to"
                class="mb-2"
                locale="nb-NO"
                :start-weekday="1"
              ></b-form-datepicker>
            </b-form-group>
            <b-form-group label="Giftcard value" label-for="sum">
              <b-form-input id="sum" v-model="newGiftcard.sum" class="mb-2" type="number"></b-form-input>
            </b-form-group>
            <b-form-group label="Voucher Code" label-for="voucher-code-id">
              <b-input-group>
                <b-form-input v-model="newGiftcard.code" id="voucher-code-id" placeholder="e.g 52893JD93J" :min="0" />
                <template #append>
                  <b-button variant="success" @click="generateCode">Generate</b-button>
                </template>
              </b-input-group>
              <b-button class="mt-3" variant="primary" :disabled="isLoading" type="submit" @click.prevent="saveGiftcard">
                  Create giftcard
                  <b-spinner v-show="isLoading" class="ml-2" small label="Loading" />
              </b-button
              >
            </b-form-group>
          </form>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col>
          <div class="custom-control custom-control-inline custom-radio">
            <input
                type="radio"
                class="custom-control-input"
                id="giftcard-search-all"
                v-model="giftcardSearchType"
                value="all"
            />
            <label class="custom-control-label" for="giftcard-search-all">All vouchers</label>
          </div>
          <div class="custom-control custom-control-inline custom-radio">
            <input
                type="radio"
                class="custom-control-input"
                id="giftcard-search-cash"
                v-model="giftcardSearchType"
                value="cash"
            />
            <label class="custom-control-label" for="giftcard-search-cash">Cash vouchers</label>
          </div>
          <div class="custom-control custom-control-inline custom-radio">
            <input
                type="radio"
                class="custom-control-input"
                id="giftcard-search-product"
                v-model="giftcardSearchType"
                value="product"
            />
            <label class="custom-control-label" for="giftcard-search-product">Product vouchers</label>
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col>
          <input type="text" placeholder="Giftcard ID" class="form-control" v-model="filterById" />
        </b-col>
        <b-col>
          <input type="text" placeholder="Giftcard Code" class="form-control" v-model="filterByCode" />
          <div class="custom-control custom-checkbox custom-checkbox-right">
            <input
                type="checkbox"
                class="custom-control-input"
                id="filterByCodeWithHistory"
                v-model="filterByCodeWithHistory"
            />
            <label class="custom-control-label" for="filterByCodeWithHistory">
              Search in transaction history
            </label>
          </div>
        </b-col>
      </b-row>

      <b-table ref="table" :fields="fields" :items="giftcardArr">
        <template v-slot:cell(code)="{ value, item }">
          <span>{{ value }}</span>
          <b-badge v-if="voucherStatus(item)" class="ml-2">
            {{ voucherStatusText(item) }}
          </b-badge>
          <b-badge v-if="item.split_at" class="ml-2">
            Split from {{ item.split_from }}
            <br />
            at {{ formatDate(item.split_at) }}
          </b-badge>
          <b-button variant="primary" v-b-modal="`giftcard-${item.id}`" size="sm" class="ml-2">
            <i class="fa fa-info" />
          </b-button>
          <b-button
            class="ml-2"
            :href="`${rootUrl}/giftcard/${item.id}/download`"
            target="_BLANK"
            variant="primary"
            size="sm"
            title="download voucher"
          >
            <i class="fa fa-download" />
          </b-button>
          <b-modal :id="`giftcard-${item.id}`" title="Giftcard History">
            <giftcard-history :giftcard="item" />
          </b-modal>
        </template>
        <template v-slot:cell(status)="{ value }">
          <b-badge :variant="value === 'Active' || value === 'Redeemed' ? 'primary' : 'danger'">{{ value }}</b-badge>
        </template>
        <template v-slot:cell(open)="{ value }">
          <b-badge :variant="value ? 'primary' : 'warning'">{{ String(value).toUpperCase() }}</b-badge>
        </template>
        <template v-slot:cell(refundable)="{ value }">
          <b-badge :variant="value ? 'primary' : 'warning'">{{ String(value).toUpperCase() }}</b-badge>
        </template>
        <template v-slot:cell(type)="{ value }">
          <strong>{{ (value === 'product' || value === 'product_variations') ? 'Product' : 'Cash' }} voucher</strong>
        </template>
        <template v-slot:cell(actions)="{ item }">
          <b-col cols="auto" class="giftcard-actions">
            <b-button
              v-if="item.status !== 'Refunded' && item.order_paid && !isExpired(item) && item.exchanged_at === null && item.redeemed_at === null"
              v-b-modal="`extend-valid-date-${item.id}`"
              variant="primary"
              size="sm"
              title="Extend valid date"
              class="mr-2"
            >
              Extend
            </b-button>&nbsp;

            <b-modal
              title="Extend Valid Date"
              :id="`extend-valid-date-${item.id}`"
              v-slot="{ ok, cancel }"
              @ok="giftcardExtended(item)"
              hide-footer
            >
              <order-detail-date-form :giftcard="item" @cancel="cancel" @updated="giftcardExtended(item)" />
            </b-modal>

            <b-button
              v-if="canBeExchanged(item)"
              v-b-modal="`exchange-giftcard-${item.id}`"
              variant="primary"
              size="sm"
              title="Exchange"
              class="mr-2"
            >
              Exchange
            </b-button>&nbsp;

            <b-modal title="Confirm Exchange" :id="`exchange-giftcard-${item.id}`" @ok="exchangeGiftcard(item)">
              Are you sure that you want to exchange this giftcard?
              <form>
                <div class="form-group">
                  <label>Enter email of person to recieve the exchanged giftcard PDF</label>
                  <input type="email" v-model="exchangeEmail" class="form-control" />
                </div>
              </form>
            </b-modal>

            <b-button
              v-b-modal="`deactivate-giftcard-${item.id}`"
              variant="danger"
              size="sm"
              title="Deactivate giftcard"
              v-if="item.status === 'Active' && item.redeemed_at === null && item.exchanged_at === null"
              :disabled="activateDeactivateLoading === item.id"
              class="mr-2"
            >
              Deactivate
              <b-spinner v-show="activateDeactivateLoading === item.id" class="ml-2" small label="Loading" />
            </b-button>&nbsp;

            <b-modal title="Confirm deactivation" :id="`deactivate-giftcard-${item.id}`" @ok="deactivateGiftcard(item)">
              Are you sure that you want to deactivate this giftcard?
            </b-modal>

            <b-button
              v-b-modal="`activate-giftcard-${item.id}`"
              variant="primary"
              size="sm"
              title="Deactivate giftcard"
              v-if="item.status === 'Inactive' && item.redeemed_at === null && (item.order_paid || item.order_detail_id === null)"
              :disabled="activateDeactivateLoading === item.id"
              class="mr-2"
            >
              Activate
              <b-spinner v-show="activateDeactivateLoading === item.id" class="ml-2" small label="Loading" />
            </b-button>&nbsp;

            <b-modal title="Confirm activation" :id="`activate-giftcard-${item.id}`" @ok="activateGiftcard(item)">
              Are you sure that you want to activate this giftcard?
            </b-modal>

            <b-button
              variant="primary"
              size="sm"
              class="mr-2"
              title="Revert value back to the giftcard"
              @click="revertValueToGiftcard(item)"
              v-if="item.is_in_cart"
              :disabled="loading.revertValueToGiftcard"
            >
              In Cart
              <b-spinner v-show="loading.revertValueToGiftcard" class="ml-2" small label="Loading" />
            </b-button>
          </b-col>
        </template>
      </b-table>
      <!-- pagination -->
      <b-pagination
        v-if="meta.total > meta.per_page"
        v-model="meta.current_page"
        :total-rows="meta.total"
        :per-page="meta.per_page"
        @change="paginationChange"
      />
    </b-card>
  </div>
</template>

<script>
import { giftcard } from '@/api'
import { formatDate, formatMoney } from './page-helpers'
import GiftcardHistory from './OrdersPage/GiftcardHistory.vue'
import OrderDetailDateForm from './OrdersPage/OrderDetailDateForm.vue'

export default {
  name: 'GiftcardPage',
  components: { GiftcardHistory, OrderDetailDateForm },
  data() {
    return {
      giftcardSearchType: 'all',
      giftcardArr: [],
      rootUrl: process.env.VUE_APP_API_URL,
      exchangeEmail: '',
      addGiftcard: false,
      combineGiftcards: false,
      giftcardsToCombine: [{ code: '' }, { code: '' }],
      filterById: this.$route.query.id,
      filterByCode: this.$route.query.code,
      filterByCodeWithHistory: Boolean(this.$route.query.history),
      newGiftcard: {
        code: '',
        sum: 1000,
        valid_to: null,
      },
      meta: {
        current_page: this.$route.query.page,
        from: 0,
        last_page: 1,
        path: '',
        per_page: 0,
        to: 0,
        total: 0,
      },
      isLoading: false,
      loading: {
        revertValueToGiftcard: false,
      },
      activateDeactivateLoading: null,
      formatDate,
    }
  },
  computed: {
    giftcard() {
      return giftcard
    },
    fields() {
      return [
        { key: 'id', label: 'ID', sortable: false },
        { key: 'code' },
        { key: 'sum', formatter: value => formatMoney(value) },
        { key: 'full_sum', formatter: value => formatMoney(value) },
        { key: 'status' },
        { key: 'open', label: 'Cash' },
        { key: 'refundable' },
        { key: 'type' },
        { key: 'valid_to', formatter: value => formatDate(value) },
        { key: 'actions', label: 'Actions' },
      ]
    },
  },
  async mounted() {
    await this.loadContent()
  },
  methods: {
    async loadContent() {
      const res = await giftcard.go(this.meta.current_page, {
        filter: {
          id: this.$route.query.id,
          code: this.$route.query.code,
          history: Boolean(this.$route.query.history),
          type: this.giftcardSearchType,
        },
      })
      this.giftcardArr = res.data()
      this.meta = res.meta
    },
    generateCode() {
      this.newGiftcard.code = Math.floor(Math.random() * 1e12)
    },
    voucherStatus(giftcard) {
      if (giftcard.refunded_at) {
          return `danger`
      }

      if (giftcard.redeemed_at) {
          return `primary`
      }

      if (giftcard.exchanged_at) {
        return `primary`
      }

      if (this.isExpired(giftcard)) {
          return `secondary`
      }
    },
    voucherStatusText(giftcard) {
      if (giftcard.exchanged_at) {
          return ` Exchanged at ${formatDate(giftcard.exchanged_at)}`
      }

      if (giftcard.refunded_at) {
          return ` Refunded at ${formatDate(giftcard.refunded_at)}`
      }

      if (giftcard.redeemed_at) {
          return ` Redeemed at ${formatDate(giftcard.redeemed_at)}`
      }

      if (this.isExpired(giftcard)) {
          return ` Expired at ${formatDate(giftcard.valid_to)}`
      }
    },
    isExpired(giftcard) {
      return Date.now() - Date.parse(giftcard.valid_to) > 0
    },
    async exchangeGiftcard(item) {
      await giftcard.exchange([item.code], this.exchangeEmail)
      this.loadContent()
      this.$bvModal.hide(`exchange-giftcard-${item.id}`)
      this.$bvToast.toast(`Giftcard exchanged`, {
        title: 'Giftcard exchanged',
        variant: 'primary',
      })
    },
    async deactivateGiftcard(item) {
      this.activateDeactivateLoading = item.id;
      await giftcard.update(item.id, { status: 0, order_detail_id: item.order_detail_id, })
      this.loadContent()
      this.activateDeactivateLoading = null;
      this.$bvToast.toast(`Giftcard with code ${item.code} was deactivated`, {
        title: 'Giftcard deactivated',
        variant: 'primary',
      })
    },
    async activateGiftcard(item) {
      this.activateDeactivateLoading = item.id;
      await giftcard.update(item.id, { status: 1, order_detail_id: item.order_detail_id, })
      this.loadContent()
      this.activateDeactivateLoading = null;
      this.$bvToast.toast(`Giftcard with code ${item.code} was activated`, {
        title: 'Giftcard activated',
        variant: 'primary',
      })
    },

    saveGiftcard() {
      let errorMessage = null;

      if (!this.newGiftcard.valid_to) {
        errorMessage = 'Please select an expiration date to create a cash voucher';
      }

      if (!this.newGiftcard.sum || parseFloat(this.newGiftcard.sum) <= 0) {
        errorMessage = 'Please enter a value to create a cash voucher.';
      }

      if (!this.newGiftcard.code) {
        errorMessage = 'Please generate a code to create a cash voucher.';
      }

      if(errorMessage) {
        this.$bvToast.toast(errorMessage, {title: 'Something went wrong...', variant: 'danger'});
        return;
      }

      this.isLoading = true

      giftcard.add({
        valid_to: this.newGiftcard.valid_to,
        code: this.newGiftcard.code,
        sum: this.newGiftcard.sum * 100,
        full_sum: this.newGiftcard.sum * 100,
        original_sum: this.newGiftcard.sum * 100,
        status: 1,
      }).then(({data}) => {
        this.newGiftcard = {
          sum: 1000,
          code: '',
          valid_to: null,
        }

        this.$bvToast.toast(`Giftcard with code ${data.code} was created`, {
          title: 'Giftcard created',
          variant: 'primary',
        })
        this.isLoading = false
        this.loadContent()
      })
    },
    giftcardUpdated() {
      this.loadContent()
    },
    removeBulletPoint(index) {
      if (this.giftcardsToCombine.length === 1) {
        return (this.giftcardsToCombine = [{ code: '' }])
      }

      this.giftcardsToCombine.splice(index, 1)
    },
    addEmptyBulletPoint() {
      this.giftcardsToCombine.push({ code: '' })
    },
     doCombine() {
      giftcard.combine(this.giftcardsToCombine.map(g => g.code)).then(({data: {code}}) => {
        this.$bvToast.toast(`Vouchers combined`, {
          title: `The vouchers were combined into a cash voucher with number ${code}.`,
          variant: 'primary',
        })

        this.loadContent()
      }).catch(error => {
        this.$bvToast.toast(error.message, {
          title: 'Something went wrong.',
          variant: 'danger',
        })
      })
    },
    giftcardExtended(item) {
      this.$bvModal.hide(`extend-valid-date-${item.id}`)
      this.$bvToast.toast(`Giftcard extended`, {
        title: 'Giftcard extended',
        variant: 'primary',
      })
      this.loadContent()
    },
    paginationChange(page) {
      const queryObj = {
        page: page,
        id: this.$route.query.id,
        code: this.$route.query.code,
        history: Boolean(this.$route.query.history),
      }
      this.$router.push({ name: 'giftcards', query: queryObj })
      this.meta.current_page = page

      this.loadContent()
    },

    handleFilterbyId(value) {
      this.$router.push({ name: 'giftcards', query: { id: value, type: this.giftcardSearchType, page: 1 } })
      this.meta.current_page = 1
      this.loadContent()
    },

    handleFilterbyCode(value) {
      this.$router.push({ name: 'giftcards', query: { code: value, history: this.filterByCodeWithHistory, type: this.giftcardSearchType, page: 1 } })
      this.meta.current_page = 1
      this.loadContent()
    },

    handleFilterbyCodeWithHistory(value) {
      this.$router.push({ name: 'giftcards', query: { code: this.filterByCode, history: value, type: this.giftcardSearchType, page: 1 } })
      this.meta.current_page = 1
      this.loadContent()
    },

    canBeExchanged(item) {
      return item.exchanged_at === null
          && item.redeemed_at === null
          && item.type === 'product'
          && item.status !== 'Refunded'
          && !this.isExpired(item)
          && item.order_paid
    },

    revertValueToGiftcard(item) {
      this.loading.revertValueToGiftcard = true

      giftcard.revertFromCart(item.id)
        .then(({ data }) => {
          this.$bvToast.toast(`Giftcard with code ${data.code} was reverted`, {
            title: 'Giftcard reverted',
            variant: 'primary',
          })
          this.loadContent()
        })
        .catch((error) => {
          this.$bvToast.toast(error.message, { title: 'Something went wrong...', variant: 'danger' })
        })
        .finally(() => {
          this.loading.revertValueToGiftcard = false
        })
    }
  },
  watch: {
    giftcardSearchType(newVal) {
      if (this.filterById !== '' && typeof this.filterById !== 'undefined' && this.filterById !== null) {
        this.handleFilterbyId(this.filterById)
        return
      }

      if (this.filterByCode !== '' && typeof this.filterByCode !== 'undefined' && this.filterByCode !== null) {
        this.handleFilterbyCode(this.filterByCode)
        return
      }

      this.handleFilterbyCodeWithHistory(this.filterByCodeWithHistory)
    },

    filterById(newVal) {
      this.handleFilterbyId(newVal)
    },

    filterByCode(newVal) {
      this.handleFilterbyCode(newVal)
    },

    filterByCodeWithHistory(newVal) {
      this.handleFilterbyCodeWithHistory(newVal)
    },
  },
}
</script>

<style scoped>
.custom-checkbox-right {
  display: flex;
  justify-content: flex-end;
  margin-top: 4px;

  & .custom-control-label {
    padding-right: 1.5rem;

    &::before, &::after {
      left: unset;
      right: 0;
    }
  }
}

.giftcard-actions {
  font-size: 0;
}

.giftcard-actions button {
  font-size: revert;
}
</style>
